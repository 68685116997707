import store from "@/store";
import moment from "moment";

interface Clock {
  hours: number;
  minutes: number;
  seconds: number;
}

// 출력할 포맷이 다양하기에 한군데 모아서 관리
class DateTime {
  static readonly MMDD: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
  };

  static readonly HHMM: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };

  static readonly HHMMSS: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  // ex) Thu Dec 08 2022 14:15:14 GMT+0900 (한국 표준시)
  static timestampToDate(
    timestamp?: number | Date | string | null
  ): Date | null {
    if (timestamp === null || timestamp === undefined) {
      return null;
    } else if (timestamp instanceof Date) {
      return timestamp;
    }

    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  }

  // ex) 2022. 12. 8. 오후 2:15:14
  static toString(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? date.toLocaleString(store.getters.setting.locale)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 1670476514266
  static toTimestamp(
    date?: string | Date | null,
    alt?: string
  ): number | string {
    return date ? new Date(date).getTime() : alt === undefined ? "n/a" : alt;
  }

  // ex) 2022. 12. 8.
  static toDateString(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTime.timestampToDate(timestamp);

    return date
      ? date.toLocaleDateString(store.getters.setting.locale)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 2022-12-08
  static toYYMMDD(
    timestamp?: number | Date | string | null,
    alt?: string
  ): string {
    const date = DateTime.timestampToDate(timestamp);
    moment.locale(store.getters.setting.locale);

    return date
      ? moment(date).format("YYYY-MM-DD")
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 	12. 08.
  static toMMDD(
    timestamp?: number | Date | string | null,
    alt?: string
  ): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? date.toLocaleDateString(store.getters.setting.locale, DateTime.MMDD)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 오후 2:15:14
  static toTimeString(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(store.getters.setting.locale)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex)	14:15
  static toHHMM(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(store.getters.setting.locale, DateTime.HHMM)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 14:15:14
  static toHHMMSS(timestamp?: number | Date | null, alt?: string): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(store.getters.setting.locale, DateTime.HHMMSS)
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 2022-12-08
  static toCalendarDate(
    timestamp?: number | Date | string | null,
    alt?: string
  ): string {
    const date = DateTime.timestampToDate(timestamp);
    if (date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return [
        year.toString(),
        month.toString().padStart(2, "0"),
        day.toString().padStart(2, "0"),
      ].join("-");
    }
    return alt === undefined ? "n/a" : alt;
  }

  // ex) 14:15
  static toCalendarTime(timestamp?: number | Date, alt?: string): string {
    return DateTime.toHHMM(timestamp, alt);
  }

  // ex) 2022-12-08 14:15
  static toCalendar(timestamp?: number | Date, alt?: string): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? `${DateTime.toCalendarDate(timestamp)} ${DateTime.toCalendarTime(
          timestamp
        )}`
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex) 2022-12-08 오후 2:15:14
  static toDateHyphenTimeMeridiemHHMMSS(
    timestamp?: number | Date,
    alt?: string
  ): string {
    const date = DateTime.timestampToDate(timestamp);
    return date
      ? `${DateTime.toCalendarDate(timestamp)} ${date
          .toLocaleString(store.getters.setting.locale)
          .split(" ")
          .splice(-2)
          .join(" ")}`
      : alt === undefined
      ? "n/a"
      : alt;
  }

  // ex)
  static durationToHHMMSS(duration: number | null): string {
    const clocks = [0, 0, 0]; // hours, minutes, seconds
    if (duration) {
      clocks[2] = duration / 1000;
      clocks[0] = Math.floor(clocks[2] / 3600);
      clocks[2] %= 3600;
      clocks[1] = Math.floor(clocks[2] / 60);
      clocks[2] %= 60;
    }
    return clocks.map((clock) => clock.toString(10).padStart(2, "0")).join(":");
  }

  // ex)
  static hhmmssToClock(hhmmss?: string | null): Clock | null {
    if (hhmmss === null || hhmmss === undefined || hhmmss === "") {
      return null;
    }

    const clock: Clock = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    const tokens = hhmmss.split(":");
    switch (tokens.length) {
      case 3:
        clock.seconds = parseInt(tokens[2], 10);
      // fallthrough
      case 2:
        clock.minutes = parseInt(tokens[1], 10);
      // fallthrough
      case 1:
        clock.hours = parseInt(tokens[0], 10);
        if (Object.is(clock.hours, -0)) {
          clock.minutes = -clock.minutes;
        }
        break;
      default:
        throw Error("Invalid time");
    }

    return clock;
  }

  // ex)
  static hhmmssToDate(hhmmss?: string | null): Date | null {
    const clock = DateTime.hhmmssToClock(hhmmss);
    if (!clock) {
      return null;
    }

    const date = new Date();
    date.setSeconds(clock.seconds);
    date.setMinutes(clock.minutes);
    date.setHours(clock.hours);

    return date;
  }

  // ex)
  static hhmmssToTimeString(hhmmss?: string | null, alt?: string): string {
    const date = DateTime.hhmmssToDate(hhmmss);
    return date ? DateTime.toTimeString(date) : alt === undefined ? "n/a" : alt;
  }

  // ex)
  static timeGapToMinutes(timeGap: string): number {
    const clock = DateTime.hhmmssToClock(timeGap);
    return clock ? clock.hours * 60 + clock.minutes : 0;
  }

  // ex)
  static timeGapToSeconds(timeGap: string): number {
    const clock = DateTime.hhmmssToClock(timeGap);
    return clock
      ? clock.hours * 60 * 60 + clock.minutes * 60 + clock.seconds
      : 0;
  }

  // ex)
  static timeGapToMinutesString(timeGap: string): string {
    const minutes = DateTime.timeGapToMinutes(timeGap);
    return minutes > 0 ? `+${minutes}` : minutes.toString();
  }

  // ex)
  static timeGapToTimeString(hhmmss: string, timeGap: string): string {
    const date = DateTime.hhmmssToDate(hhmmss);
    if (!date) {
      return "-";
    }
    date.setMinutes(date.getMinutes() + DateTime.timeGapToMinutes(timeGap));
    return DateTime.toTimeString(date);
  }

  // ex)
  static timeToHHMMSS(startTime: string, endTime: string): string {
    const firstSeconds = this.timeGapToSeconds(startTime);
    const secondSeconds = this.timeGapToSeconds(endTime);

    const seconds = firstSeconds - secondSeconds;
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor(seconds % 60);

    const clockHour = hour != 0 ? hour + "시간" : "";
    const clockMin = min != 0 ? min + "분 " : "";
    const clockSec = sec != 0 ? sec + "초" : "0초";

    return clockHour + clockMin + clockSec;
  }

  // ex)
  static secondsToHHMMSS(seconds: number): string {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = Math.round(seconds % 60);

    const clockHour = hour != 0 ? hour + "시간" : "";
    const clockMin = min != 0 ? min + "분 " : "";
    const clockSec = sec != 0 ? sec + "초" : "0초";

    return clockHour + clockMin + clockSec;
  }

  // ex)
  static calculatePreviousDate(days: number): string {
    const todayTimestamp = DateTime.toTimestamp(new Date()) as number;
    const previousDate = todayTimestamp - days * 60 * 60 * 24 * 1000;

    return DateTime.toYYMMDD(previousDate);
  }

  // ex) 4
  static dayToNumber(
    timestamp?: number | Date | string | null,
    alt?: number
  ): number {
    const date = DateTime.timestampToDate(timestamp);
    if (date) {
      return date.getDay();
    }
    return alt === undefined ? 0 : alt;
  }
}

export default DateTime;
