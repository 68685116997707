import axios, { AxiosRequestConfig } from "axios";
import { VueConstructor } from "vue";
import { Vue } from "vue-property-decorator";
import {
  ICreateCsData,
  IEditCsData,
  IPreProcessedData,
  IZendeskTicketData,
} from "@/types/csDataTypes";
import { csCustomField, csInfoDict } from "@/values/csValues";
import { DataOptions } from "vuetify";
import { centerDict } from "@/values/common";
import DateTime from "@/plugins/haii/DateTime";

class ZendeskClass {
  private static _instance: ZendeskClass;

  static get Instance(): ZendeskClass {
    return this._instance || (this._instance = new this());
  }

  // CS 조회
  async getCsData(
    condition: string,
    userCenterCode: number,
    dataOptions: DataOptions
  ): Promise<any> {
    let csUrl =
      process.env.VUE_APP_ZENDESK_URL +
      "search.json?sort_by=created_at&sort_order=desc&query=type:ticket via:web via:native_messaging via:api custom_field_10782181201561:true" +
      condition;

    if (userCenterCode !== 101) {
      csUrl =
        csUrl + " custom_field_8699492801945:" + centerDict[userCenterCode];
    }

    // Center가 정해져 있는 경우, 해당 값을 먼저 csUrl에 넣어주고 Page option을 넣어줘야 에러가 나지 않음
    csUrl =
      csUrl + `&per_page=${dataOptions.itemsPerPage}&page=${dataOptions.page}`;

    const init: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_ZENDESK_AOUTH_TOKEN}`,
      },
    };
    try {
      return await axios.get(csUrl, init);
    } catch (e) {
      console.log(e);
    }
  }

  // Zendesk data의 custom_fields object에서 id로 원하는 값 조회
  findFieldValue(data: IZendeskTicketData, id: number | string) {
    return data.custom_fields?.find((field) => {
      if (field.id === id) {
        return field.value;
      } else {
        return "";
      }
    });
  }

  // Zendesk API를 통해 조회된 데이터를 필요에 맞게 가공
  preprocessChartData(chartData: Array<IZendeskTicketData>) {
    return chartData.map((data: IZendeskTicketData) => {
      const cs = this.findFieldValue(data, csCustomField["csType"])?.value;
      const separatedCs =
        cs && cs.length
          ? cs.toUpperCase().replaceAll("_", " ").split(":")
          : ["", ""];

      return {
        id: data.id,
        csType: separatedCs[0] as string,
        csIssue: separatedCs[1] as string,
        createDate: data.created_at
          ? DateTime.toDateHyphenTimeMeridiemHHMMSS(new Date(data.created_at))
          : null,
        updateDate: data.updated_at
          ? DateTime.toDateHyphenTimeMeridiemHHMMSS(new Date(data.updated_at))
          : null,
        examineeName: this.findFieldValue(data, csCustomField["examineeName"])
          ?.value,
        examineeCenter: this.findFieldValue(
          data,
          csCustomField["examineeCenter"]
        )?.value,
        reservationNum: this.findFieldValue(
          data,
          csCustomField["reservationNum"]
        )?.value,
        phoneOS: this.findFieldValue(data, csCustomField["phoneOS"])?.value,
        phoneType: this.findFieldValue(data, csCustomField["phoneType"])?.value,
        appVersion: this.findFieldValue(data, csCustomField["appVersion"])
          ?.value,
        problemDate: this.findFieldValue(data, csCustomField["problemDate"])
          ?.value,
        problemTime: this.findFieldValue(data, csCustomField["problemTime"])
          ?.value,
        csManager: this.findFieldValue(data, csCustomField["csManager"])?.value,
        reporterName: this.findFieldValue(data, csCustomField["reporterName"])
          ?.value,
        reporterCenter: this.findFieldValue(
          data,
          csCustomField["reporterCenter"]
        )?.value,
        relatedUuid: this.findFieldValue(data, csCustomField["relatedUuid"])
          ?.value,
        status: data.custom_status_id,
      };
    });
  }

  // Zendesk comment 조회
  async getCsComments(data: IPreProcessedData): Promise<any> {
    const url =
      process.env.VUE_APP_ZENDESK_URL + `tickets/${data.id}/comments.json`;
    const init = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_ZENDESK_AOUTH_TOKEN}`,
      },
    };

    try {
      const commentData = await axios.get(url, init);
      const comments = [...commentData.data.comments];

      return comments.map((comment: any) => {
        if (!comment.public) return;

        if (comment.via.channel === "web") {
          comment.plain_body = comment.plain_body.replace(/^\s*/, "");
        } else {
          comment.plain_body = comment.plain_body.replaceAll("\n\n", "\n");
          comment.plain_body = comment.plain_body.replace(
            /(\(\d{2}:\d{2}:\d{2}\))(.+User.+?: )/g,
            `\n$1 ${data.examineeName}:\n`
          );
          comment.plain_body = comment.plain_body.replace(
            /(\(\d{2}:\d{2}:\d{2}\))(.+?: )/g,
            "\n$1$2\n"
          );
          comment.plain_body = comment.plain_body.replace(/ ?\n */g, "\n");
          comment.plain_body = comment.plain_body.replace(/^\s*|^\n/, "");
          comment.html_body = comment.plain_body.replaceAll("\n", "<br />");

          if (comment.html_body.includes("업로드함:<br />")) {
            const attachments = comment.html_body
              .split("업로드함:<br />")
              .slice(1);
            attachments.forEach((attachment: string) => {
              const attachmentData = attachment.split("<br />").slice(0, 4);

              const attachmentName = attachmentData[0];
              const attachmentUrl = attachmentData[1].replace("URL: ", "");
              const attachmentType = attachmentData[2]
                .replace("유형: ", "")
                .split("/")[0];
              let attachmentHtml = "";

              if (attachmentType === "image") {
                attachmentHtml = `<a href=${attachmentUrl} target="_blank"> <img src=${attachmentUrl} alt=${attachmentName} style="width: 10vw; height: 100%; display: inline-block" /> </a>`;
              } else {
                attachmentHtml = `<a href=${attachmentUrl} target="_blank"> <p style="padding: 0; display: inline-block"> ${attachmentName} </p> </a>`;
              }

              const attachmentString = attachmentData.join("<br />");
              comment.html_body = comment.html_body.replace(
                attachmentString,
                attachmentHtml
              );
            });
          }
        }
        return comment;
      });
    } catch (e) {
      console.log(e);
    }
  }

  // 파일 첨부를 위해 Zendesk Server에 binary file 전송 후 토큰 return
  async createFileToken(file: File): Promise<string> {
    const url =
      process.env.VUE_APP_ZENDESK_URL + `uploads?filename=${file.name}`;
    const data = file;
    const init = {
      headers: {
        "Content-Type": "Content-Type: application/binary",
        Authorization: `Bearer ${process.env.VUE_APP_ZENDESK_AOUTH_TOKEN}`,
      },
    };

    try {
      const tokenData = await axios.post(url, data, init);
      return tokenData.data.upload.token;
    } catch (e) {
      return e + "";
    }
  }

  // Zendesk Ticket 생성
  async createCsTicket(
    csData: ICreateCsData,
    fileTokens: string[]
  ): Promise<any> {
    const url = process.env.VUE_APP_ZENDESK_URL + `tickets.json`;
    const init = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_ZENDESK_AOUTH_TOKEN}`,
      },
    };

    const data = {
      ticket: {
        subject: `마음검진 어드민에서 생성된 CS입니다. [${csData.examineeCenter}-${csData.examineeName}]`,
        comment: {
          body: csData.csComment,
          uploads: fileTokens,
        },
        requester: {
          // 수검자 명
          name: csData.examineeName,
        },
        custom_fields: [
          {
            // CS 유형
            id: 8368673312153,
            value: (csData.csType + ":" + csData.csIssue)
              .replaceAll(" ", "_")
              .toLowerCase(),
          },
          {
            // 수검자 명
            id: 10341740261913,
            value: csData.examineeName,
          },
          {
            // 검진센터
            id: 8699492801945,
            value: csData.examineeCenter,
          },
          {
            // 예약번호
            id: 8368498557081,
            value: csData.reservationNum?.replaceAll("-", ""), // TODO: replaceALl 추후 제거
          },
          {
            // 휴대폰 OS (null / 애플 / 안드로이드)
            id: 7974353730329,
            value: csData.phoneOS,
          },
          {
            // 휴대폰 기종
            id: 7974128146969,
            value: csData.phoneType,
          },
          {
            // 앱 버전
            id: 8728550741145,
            value: csData.appVersion,
          },
          {
            // 등록자 명
            id: 10310823249433,
            value: csData.reporterName,
          },
          {
            // 등록자 센터
            id: 10310831539609,
            value: csData.reporterCenter,
          },
          {
            // 문제 발생일
            id: 9196460711833,
            value: csData.problemDate,
          },
          {
            // 문제 발생 시간
            id: 11451784659225,
            value: csData.problemTime,
          },
          {
            // CS 담당자 명
            id: 11153186533529,
            value: csData.csManager ?? "박수연",
          },
          {
            // 공개 여부: Admin에서 생서된 CS는 항상 true
            id: 10782181201561,
            value: true,
          },
          {
            // 알림 받을 관계자 UUID
            id: 11228062088601,
            value:
              csData.userUuid != process.env.VUE_APP_ADMIN_SERVICE_UUID
                ? `${csData.userUuid}&${process.env.VUE_APP_ADMIN_SERVICE_UUID}`
                : process.env.VUE_APP_ADMIN_SERVICE_UUID,
          },
        ],
      },
    };

    try {
      return axios.post(url, data, init);
    } catch (e) {
      console.log(e);
    }
  }

  // Zendesk Ticket 내용 수정
  async updateCsTicket(
    originalData: IPreProcessedData,
    updatedData: IEditCsData,
    userUuid: string
  ): Promise<any> {
    const customFieldParams = [];
    const updatedContents: Array<any> = [];
    let statusParam = null;

    // CS 유형이나 이슈 요약이 변경된 경우
    if (
      originalData.csType != updatedData.csType ||
      originalData.csIssue != updatedData.csIssue
    ) {
      customFieldParams.push({
        id: csCustomField.csType,
        value: (updatedData.csType + ":" + updatedData.csIssue)
          .replaceAll(" ", "_")
          .toLowerCase(),
      });
      updatedContents.push({
        content: "CS 유형",
        originalValue: `${originalData.csType}:${originalData.csIssue}`,
        updatedValue: `${updatedData.csType}:${updatedData.csIssue}`,
      });
    }

    // 수검자 정보가 변경된 경우
    if (
      originalData.examineeName != updatedData.examineeName ||
      originalData.examineeCenter != updatedData.examineeCenter ||
      originalData.reservationNum != updatedData.reservationNum
    ) {
      customFieldParams.push(
        {
          id: csCustomField.examineeName,
          value: updatedData.examineeName,
        },
        {
          id: csCustomField.examineeCenter,
          value: updatedData.examineeCenter,
        },
        {
          id: csCustomField.reservationNum,
          value: updatedData.reservationNum,
        }
      );
      updatedContents.push({
        content: "수검자 정보",
        originalValue: `${originalData.examineeName}(${originalData.examineeCenter}센터, ${originalData.reservationNum})`,
        updatedValue: `${updatedData.examineeName}(${updatedData.examineeCenter}센터, ${updatedData.reservationNum})`,
      });
    }

    // 티켓 처리 상태가 변경된 경
    if (originalData.status != updatedData.status) {
      statusParam = updatedData.status;
    }

    // 관계자 uuid에 cs 수정자의 uuid가 없는 경우
    if (!originalData.relatedUuid?.includes(userUuid)) {
      customFieldParams.push({
        id: 11228062088601,
        value: originalData.relatedUuid
          ? originalData.relatedUuid + `&${userUuid}`
          : `${userUuid}`,
      });
    }

    const csItems = [
      "phoneOS",
      "phoneType",
      "appVersion",
      "reporterName",
      "reporterCenter",
      "problemDate",
      "problemTime",
      "csManager",
    ];

    // 이외 데이터가 변경된 경우
    for (const k of csItems) {
      if (
        originalData[k as keyof IPreProcessedData] !=
        updatedData[k as keyof IEditCsData]
      ) {
        customFieldParams.push({
          id: csCustomField[k],
          value: updatedData[k as keyof IEditCsData],
        });
        updatedContents.push({
          content: csInfoDict[k],
          originalValue: `${originalData[k as keyof IPreProcessedData] ?? ""}`,
          updatedValue: `${updatedData[k as keyof IEditCsData] ?? ""}`,
        });
      }
    }

    // 변경된 내용이 있는 경우 서버로 전송
    if (!!customFieldParams.length || !!statusParam) {
      const url =
        process.env.VUE_APP_ZENDESK_URL + `tickets/${originalData.id}`;
      let data = {};
      // customField와 status가 모두 변경된 경우
      if (!!customFieldParams.length && !!statusParam) {
        data = {
          ticket: {
            custom_status_id: statusParam,
            custom_fields: customFieldParams,
          },
        };
      }
      // custom field만 변경된 경우
      else if (!statusParam) {
        data = {
          ticket: {
            custom_fields: customFieldParams,
          },
        };
      }
      // status만 변경된 경우
      else {
        data = {
          ticket: {
            custom_status_id: statusParam,
          },
        };
      }
      const init = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.VUE_APP_ZENDESK_AOUTH_TOKEN}`,
        },
      };

      try {
        const res = await axios.patch(url, data, init);
        return {
          res: res,
          updatedContents: updatedContents,
        };
      } catch (e) {
        // error
        console.log(e);
      }
    }
    // 변경된 부분이 없는 경우
    else {
      return "수정된 정보가 없습니다.";
    }
  }

  // Zendesk Ticket에 댓글 추가
  async addComment(
    ticketId: number,
    writer: string,
    userUuid: string,
    relatedUuid: string,
    additionalComment: string,
    fileTokens: string[]
  ) {
    let data = {};
    // 관계자 uuid에 cs 수정자의 uuid가 없는 경우
    if (relatedUuid.includes(userUuid)) {
      data = {
        ticket: {
          comment: {
            body: `- ${writer}:\n` + additionalComment,
            public: true,
            uploads: fileTokens,
          },
          custom_fields: {
            id: 11228062088601,
            value: relatedUuid ? relatedUuid + `&${userUuid}` : `${userUuid}`,
          },
        },
      };
    } else {
      data = {
        ticket: {
          comment: {
            body: `- ${writer}:\n` + additionalComment,
            public: true,
            uploads: fileTokens,
          },
        },
      };
    }

    const url = process.env.VUE_APP_ZENDESK_URL + `tickets/${ticketId}.json`;

    const init = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.VUE_APP_ZENDESK_AOUTH_TOKEN}`,
      },
    };

    try {
      return await axios.put(url, data, init);
    } catch (e) {
      console.log(e);
    }
  }
}

declare module "vue/types/vue" {
  interface Vue {
    $Zendesk: ZendeskClass;
  }
}

const Zendesk = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$Zendesk = ZendeskClass.Instance;
  },
};

Vue.use(Zendesk);

export default Zendesk;
