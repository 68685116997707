import { VueConstructor } from "vue";
import { Vue } from "vue-property-decorator";
import axios from "axios";
import { ICreateCsData } from "@/types/csDataTypes";
// import zendesk from "@/plugins/Zendesk";

class SlackClass {
  private static _instance: SlackClass;
  static get Instance(): SlackClass {
    return this._instance || (this._instance = new this());
  }

  // Zendesk Ticket 생성 완료 시 Slack 알림
  async createdSlackAlert(
    csData: ICreateCsData,
    adminUrl: string,
    zendeskUrl: string
  ) {
    const blocks = {
      attachments: [
        {
          color: "#005282",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "[마음검진 어드민 - KMI] 문의가 발생하였습니다.",
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*CS 유형 :* [${csData.csType} - ${csData.csIssue}]`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*수검자 정보 : * ${csData.examineeName} (${csData.examineeCenter}센터, ${csData.reservationNum})`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*문제 발생 시점 :* ${csData.problemDate} ${csData.problemTime}`,
              },
            },
            {
              type: "actions",
              elements: [
                {
                  type: "button",
                  text: {
                    type: "plain_text",
                    emoji: true,
                    text: "마음검진 어드민에서 확인하기",
                  },
                  style: "primary",
                  url: adminUrl,
                },
                {
                  type: "button",
                  text: {
                    type: "plain_text",
                    emoji: true,
                    text: "Zendesk에서 확인하기",
                  },
                  url: zendeskUrl,
                },
              ],
            },
          ],
        },
      ],
    };
    try {
      return await axios.post(
        process.env.VUE_APP_SLACK_WEBHOOK_URL,
        JSON.stringify(blocks)
      );
    } catch (e: any) {
      console.log(e);
    }
  }

  // CS에 댓글이 달렸을 경우 Slack 알림
  // async addCommentSlackAlert(
  //   writer: string,
  //   comments: string,
  //   adminUrl: string,
  //   zendeskUrl: string
  // ) {
  //   const blocks = {
  //     attachments: [
  //       {
  //         color: "#005282",
  //         blocks: [
  //           {
  //             type: "header",
  //             text: {
  //               type: "plain_text",
  //               text: "[마음검진 어드민 - KMI] 댓글이 추가되었습니다.",
  //             },
  //           },
  //           {
  //             type: "section",
  //             text: {
  //               type: "mrkdwn",
  //               text: `*작성자 :* ${writer}`,
  //             },
  //           },
  //           {
  //             type: "section",
  //             text: { type: "plain_text", text: comments },
  //           },
  //           {
  //             type: "actions",
  //             elements: [
  //               {
  //                 type: "button",
  //                 text: {
  //                   type: "plain_text",
  //                   emoji: true,
  //                   text: "마음검진 어드민에서 확인하기",
  //                 },
  //                 style: "primary",
  //                 url: adminUrl,
  //               },
  //               {
  //                 type: "button",
  //                 text: {
  //                   type: "plain_text",
  //                   emoji: true,
  //                   text: "Zendesk에서 확인하기",
  //                 },
  //                 url: zendeskUrl,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   };
  //   try {
  //     return await axios.post(
  //       process.env.VUE_APP_SLACK_WEBHOOK_URL,
  //       JSON.stringify(blocks)
  //     );
  //   } catch (e: any) {
  //     console.log(e);
  //   }
  // }
  //
  // // CS 내용이 수정되었을 경우 Slack 알림
  // async updatedSlackAlert(
  //   updatedContents: any,
  //   adminUrl: string,
  //   zendeskUrl: string
  // ) {
  //   let updatedCsTypeContent = null;
  //   let updatedExamineeContent = null;
  //   const updatedContentsField: Array<any> = [];
  //
  //   updatedContents.forEach((content: any) => {
  //     if (content.content === "CS 유형" || content.content === "수검자 정보") {
  //       const data = {
  //         type: "section",
  //         text: {
  //           type: "mrkdwn",
  //           text: `*${content.content} :* ${content.originalValue ?? ""} → ${
  //             content.updatedValue
  //           }`,
  //         },
  //       };
  //
  //       if (content.content === "CS 유형") {
  //         updatedCsTypeContent = data;
  //       } else if (content.content === "수검자 정보") {
  //         updatedExamineeContent = data;
  //       }
  //     } else {
  //       updatedContentsField.push({
  //         type: "mrkdwn",
  //         text: `*${content.content} :* ${content.originalValue ?? ""} → ${
  //           content.updatedValue
  //         }`,
  //       });
  //     }
  //   });
  //
  //   const blocks = {
  //     attachments: [
  //       {
  //         color: "#005282",
  //         blocks: [
  //           {
  //             type: "header",
  //             text: {
  //               type: "plain_text",
  //               text: "[마음검진 어드민 - KMI] 문의가 수정되었습니다.",
  //             },
  //           },
  //           updatedCsTypeContent,
  //           updatedExamineeContent,
  //           {
  //             type: "section",
  //             fields: updatedContentsField,
  //           },
  //           {
  //             type: "actions",
  //             elements: [
  //               {
  //                 type: "button",
  //                 text: {
  //                   type: "plain_text",
  //                   emoji: true,
  //                   text: "마음검진 어드민에서 확인하기",
  //                 },
  //                 style: "primary",
  //                 url: adminUrl,
  //               },
  //               {
  //                 type: "button",
  //                 text: {
  //                   type: "plain_text",
  //                   emoji: true,
  //                   text: "Zendesk에서 확인하기",
  //                 },
  //                 url: zendeskUrl,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   };
  //   try {
  //     const res = await axios.post(
  //       process.env.VUE_APP_SLACK_WEBHOOK_URL,
  //       JSON.stringify(blocks)
  //     );
  //     console.log(res);
  //   } catch (e: any) {
  //     console.log(e);
  //   }
  // }
}

declare module "vue/types/vue" {
  interface Vue {
    $Slack: SlackClass;
  }
}

const Slack = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$Slack = SlackClass.Instance;
  },
};

Vue.use(Slack);

export default Slack;
