import { DataOptions } from "vuetify";
import { Base64 } from "js-base64";
import { Nested, Primitive } from "@/lib/types";
import { ToastOptions, ToastType } from "vue-toasted";
import Vue from "vue";

type $QueryType = {
  filter?: Nested;
  dataOptions?: DataOptions;
};

class Util {
  static dataIndex(dataOptions: DataOptions, index: number): number {
    return dataOptions.itemsPerPage * (dataOptions.page - 1) + index + 1;
  }

  static queryEncode(filter?: Nested, dataOptions?: DataOptions): string {
    return Base64.encode(JSON.stringify({ filter, dataOptions }));
  }

  static queryDecode(
    encoded: string | Array<string | null>,
    defaultFilter?: Nested,
    defaultDataOptions?: DataOptions
  ): $QueryType | Error {
    let query = {} as $QueryType;
    let invalidFilter: string | boolean = false;
    let invalidFilterValue: Primitive | Nested | Array<Primitive | Nested>;
    let invalidDataOptions: string | boolean = false;
    let invalidDataOptionsValue: Primitive | Nested | Array<Primitive | Nested>;
    let err: Error | undefined;

    if (!encoded) {
      return query;
    }

    try {
      query = JSON.parse(Base64.decode(encoded.toString()));

      if (query.filter) {
        if (defaultFilter) {
          Object.entries(query.filter).forEach(([key, value]) => {
            if (value === null) {
              return;
            }
            if (
              typeof value !==
              typeof defaultFilter[key as keyof typeof defaultFilter]
            ) {
              invalidFilter = key;
              invalidFilterValue = value;
            }
          });
        } else {
          invalidFilter = "DEFAULT_FILTER";
        }
      }

      if (query.dataOptions) {
        if (defaultDataOptions) {
          Object.entries(query.dataOptions).forEach(([key, value]) => {
            if (
              typeof value !==
              typeof defaultDataOptions[key as keyof typeof defaultDataOptions]
            ) {
              invalidDataOptions = key;
              invalidDataOptionsValue = value;
            }
          });
        } else {
          invalidDataOptions = "DEFAULT_DATA_OPTIONS";
        }
      }
    } catch (e) {
      err = e as Error;
    } finally {
      if (invalidFilter) {
        err = Error(`Invalid filter.${invalidFilter}: ${invalidFilterValue}`);
      } else if (invalidDataOptions) {
        err = Error(
          `Invalid dataOptions.${invalidDataOptions}: ${invalidDataOptionsValue}`
        );
      }
    }

    return err || query;
  }

  static toastMessage(
    icon: ToastType,
    message: string,
    timer?: number,
    option?: ToastOptions
  ): void {
    switch (icon) {
      case "success": {
        Vue.toasted.success(
          message,
          option
            ? option
            : {
                icon: "mdi-alert-circle-outline",
                duration: timer ? timer : 2000,
              }
        );
        break;
      }
      case "error": {
        Vue.toasted.error(
          message,
          option
            ? option
            : {
                icon: "mdi-alert-circle-outline",
                duration: timer ? timer : 2000,
              }
        );
        break;
      }
      default: {
        Vue.toasted.show("에러");
        break;
      }
    }
  }

  // Object의 값을 모두 null로 set
  static setObjectValueNull(obj: Record<any, unknown>) {
    return Object.keys(obj).map((key: keyof typeof obj) => (obj[key] = null));
  }
}

export default Util;
