// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../../../firebase.config";
import Vue from "vue";

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.prototype.$firebase = firebase;
export default firebase;
