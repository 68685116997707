import { IPreProcessedData } from "@/types/csDataTypes";

export const defaultPreProcessedData: IPreProcessedData = {
  id: null,
  csType: null,
  csIssue: null,
  createDate: null,
  updateDate: null,
  examineeName: null,
  examineeCenter: null,
  reservationNum: null,
  phoneOS: null,
  phoneType: null,
  appVersion: null,
  problemDate: null,
  problemTime: null,
  csManager: null,
  reporterName: null,
  reporterCenter: null,
  relatedUuid: null,
  status: null,
};

export const csTypeList = ["일반 문의", "요청 사항", "버그 신고", "기타 문의"];

export const csCenterList = [
  { text: "광화문", value: "광화문" },
  { text: "여의도", value: "여의도" },
  { text: "강남", value: "강남" },
  { text: "수원", value: "수원" },
  { text: "대구", value: "대구" },
  { text: "부산", value: "부산" },
  { text: "광주", value: "광주" },
];

export const csStatusDict: { [key: number]: string } = {
  7675219050521: "신규",
  7675211831193: "접수",
  7675182010009: "보류",
  7675246833945: "처리중",
  11572463749273: "응답대기중",
  7675173302937: "해결",
};

export const csStatusDictReversed: { [key: string]: number } = {
  신규: 7675219050521,
  접수: 7675211831193,
  보류: 7675182010009,
  처리중: 7675246833945,
  응답대기중: 11572463749273,
  해결: 7675173302937,
};

export const csStatusList = [
  { text: "신규", value: 7675219050521 },
  { text: "접수", value: 7675211831193 },
  { text: "보류", value: 7675182010009 },
  { text: "처리중", value: 7675246833945 },
  { text: "응답대기중", value: 11572463749273 },
  { text: "해결", value: 7675173302937 },
];

export const csCustomField: { [key: string]: number } = {
  csType: 8368673312153,
  examineeName: 10341740261913,
  examineeCenter: 8699492801945,
  reservationNum: 8368498557081,
  phoneOS: 7974353730329,
  phoneType: 7974128146969,
  appVersion: 8728550741145,
  reporterName: 10310823249433,
  reporterCenter: 10310831539609,
  problemDate: 9196460711833,
  problemTime: 11451784659225,
  csManager: 11153186533529,
  publicFlag: 10782181201561,
  relatedUuid: 11228062088601,
};

export const csInfoDict: { [key: string]: string } = {
  examineeName: "수검자 명",
  examineeCenter: "검진 센터",
  reservationNum: "예약 번호",
  phoneOS: "휴대폰 OS",
  phoneType: "휴대폰 기종",
  appVersion: "앱 버전",
  reporterName: "등록자 명",
  reporterCenter: "담당 센터",
  problemDate: "문제 발생일",
  problemTime: "문제 발생 시간",
  csManager: "CS 담당자",
  status: "처리 상태",
};

export const phoneOsList = [
  { text: "애플", value: "애플" },
  { text: "안드로이드", value: "안드로이드" },
];

export const originalCsInfo = {
  "수검자 정보": [
    "examineeName",
    "examineeCenter",
    "reservationNum",
    "phoneOS",
    "phoneType",
    "appVersion",
  ],
  "등록자 정보": [
    "reporterName",
    "reporterCenter",
    "problemDate",
    "problemTime",
  ],
  "추가 정보": ["csManager", "status"],
};

export const editCsInfo = [
  {
    title: "수검자 정보",
    data: [
      {
        subtitle: "phoneOS",
        type: "select",
        placeholder: "선택",
        item: phoneOsList,
        required: false,
      },
      {
        subtitle: "phoneType",
        type: "text",
        placeholder: "Ex. 아이폰11, 갤럭시S10",
        item: null,
        required: false,
      },
      {
        subtitle: "appVersion",
        type: "text",
        placeholder: "Ex. 1.2.0",
        item: null,
        required: false,
      },
    ],
  },
  {
    title: "등록자 정보",
    data: [
      {
        subtitle: "reporterName",
        type: "text",
        placeholder: "이름 입력",
        item: null,
        required: false,
      },
      {
        subtitle: "reporterCenter",
        type: "select",
        placeholder: "선택",
        item: null,
        required: false,
      },
      {
        subtitle: "problemDate",
        type: "date",
        placeholder: "선택",
        item: null,
        required: true,
      },
      {
        subtitle: "problemTime",
        type: "text",
        placeholder: "Ex. 오후 1:45",
        item: null,
        required: true,
      },
    ],
  },
  {
    title: "추가 정보",
    data: [
      {
        subtitle: "csManager",
        type: "text",
        placeholder: "이름 입력",
        item: null,
        required: false,
      },
      {
        subtitle: "status",
        type: "select",
        placeholder: "선택",
        item: csStatusList,
        required: false,
      },
    ],
  },
];
