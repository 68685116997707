import * as Oazapfts from "oazapfts/lib/runtime";
import * as adminAPI from "@/generated/adminapi";
import { Cookie, HaiiAuth } from "@/plugins/HaiiExAuth";
import {
  GenericResponse,
  V1AdminAdminuserInfoResponse,
  V1AdminAdminuserUpdateRequest,
  V1AdminAdminuserUpdateResponse,
  V1AdminDashboardMonthlyCountResponse,
  V1AdminDashboardOrgReportCountResponse,
  V1AdminExamineesCompanyCodesValidateResponse,
  v1AdminOrganizationReportDelete,
  V1AdminOrganizationReportInfoResponse,
  V1AdminOrgReportsCreateRequest,
  V1AdminOrgReportsResponse,
  V1AdminOrgReportsResponseDetail,
  V1AdminStatisticsAgeCountResponse,
  V1AdminStatisticsDailyCountResponse,
  V1AdminStatisticsGenderCountResponse,
  V1AdminStatisticsSymptomaticCountResponse,
  V1ExamineesListResponse,
  V1ExamineeUpdateRequest,
  V1ExamineeUpdateResponse,
  V1NotificationCreateRequest,
  V1NotificationsResponse,
  V1NotificationUpdateReadResponse,
} from "@/generated/adminapi";

export type ResponseType = { status: number; data: any };

class ServerClass {
  readonly baseUrl: string;

  constructor(apiSet: typeof adminAPI) {
    this.baseUrl = process.env.VUE_APP_SERVER_URL;
  }

  protected get requestOptions(): Oazapfts.RequestOpts {
    const jwt = Cookie.getCookie("_aat");

    return {
      baseUrl: this.baseUrl,
      headers: {
        // Authorization: `Bearer ${jwt}`,
        "X-AdminAuthorization": `Bearer ${jwt}`,
      },
    };
  }

  async manageToken() {
    await HaiiAuth.Instance.manageToken(true);
  }

  async handleResponse(response: Error | ResponseType): Promise<string> {
    if (response instanceof Error) {
      console.log({
        apiError: true,
        message: response,
      });
      return response + "";
    }

    switch ((response.data as GenericResponse).code) {
      case 401:
        break;

      default:
        console.log({
          apiError: true,
          message: response.data,
        });
        break;
    }

    return response + "";
  }
}

export class AdminServerClass extends ServerClass {
  constructor() {
    super(adminAPI);
  }

  /***** Common *****/
  // 알림
  // 알림 조회(최근 14일)
  async v1AdminNotifications(
    opts?: Oazapfts.RequestOpts
  ): Promise<V1NotificationsResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminNotifications(this.requestOptions);
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 알림 생성
  async v1AdminNotificationCreate(
    v1NotificationCreateRequest: V1NotificationCreateRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminNotificationCreate(
        v1NotificationCreateRequest,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 읽은 알림 삭제, 전체 알림 삭제
  async v1AdminNotificationsDelete(
    readFlag: "Y" | "N",
    opts?: Oazapfts.RequestOpts
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminNotificationsDelete(
        readFlag,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 특정 알림 삭제
  async v1AdminNotificationDelete(
    notificationUuid: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminNotificationDelete(
        notificationUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 알림 읽음 처리
  async v1AdminNotificationUpdateRead(
    notificationUuid: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1NotificationUpdateReadResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminNotificationUpdateRead(
        notificationUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 사용자 정보 관련
  // 사용자 정보 조회
  async v1AdminAdminuserInfo(
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminAdminuserInfoResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminAdminuserInfo(this.requestOptions);
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 사용자 정보(전화번호) 업데이트
  async v1AdminAdminuserUpdate(
    v1AdminAdminuserUpdateRequest: V1AdminAdminuserUpdateRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminAdminuserUpdateResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminAdminuserUpdate(
        v1AdminAdminuserUpdateRequest,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  /***** Dashboard *****/
  // 검진 현황 통계
  // 당월 검진 대상자 수, 수검자 수
  async v1AdminDashboardMonthlyCount(
    {
      institutionCode,
    }: {
      institutionCode?: string;
    } = {},
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminDashboardMonthlyCountResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminDashboardMonthlyCount(
        {
          institutionCode,
        },
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 검진 현황 통계
  // 전월 정산 대상자 수, 정산 처리 완료율

  // 기업 보고서 관리
  // 기업 보고서 생성 총 건, 당월 생성 건, 당일 생성 건
  async v1AdminDashboardOrgReportCount(
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminDashboardOrgReportCountResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminDashboardOrgReportCount(
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  /***** 수검자 정보 *****/
  // 수검자 정보 받아오기
  async v1AdminExaminees(
    param: {
      institutionCode?: string;
      finishFlag?: boolean;
      startDate?: string;
      endDate?: string;
      searchKeyword?: string;
      searchRgstno?: string;
      searchLCode?: string;
      companyCodeList?: any;
      uuidList?: any;
      pagination?: any;
    },
    opts?: Oazapfts.RequestOpts
  ): Promise<V1ExamineesListResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminExaminees(param, this.requestOptions);
      if (res.status != 200) {
        // 존재하지 않는 사업장 번호가 입력된 경우 BAD REQUEST
        if (res.data.message == "BAD REQUEST") {
          return res.data.message;
        }
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 수검자 정보 수정
  async v1AdminExamineeUpdate(
    examineeUuid: string,
    v1ExamineeUpdateRequest: V1ExamineeUpdateRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1ExamineeUpdateResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminExamineeUpdate(
        examineeUuid,
        v1ExamineeUpdateRequest,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  /***** 기업 보고서 *****/
  // 기업 보고서 조회
  async v1AdminOrgReports(
    param: {
      orgCode?: string;
      startDate?: string;
      endDate?: string;
      pagination?: any;
    } = {},
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminOrgReportsResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminOrgReports(param, this.requestOptions);
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 기업 보고서 생성 시 사업장 코드 유효성 검사
  async v1AdminExamineesCompanyCodesValidate(
    cmpycd: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminExamineesCompanyCodesValidateResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminExamineesCompanyCodesValidate(
        cmpycd,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 기업보고서 생성
  async v1AdminOrgReportsCreate(
    v1AdminOrgReportsCreateRequest: V1AdminOrgReportsCreateRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminOrgReportsResponseDetail | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminOrgReportsCreate(
        v1AdminOrgReportsCreateRequest,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 기업보고서 상세보기
  async v1AdminOrganizationReportInfo(
    orgReportUuid: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminOrganizationReportInfoResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminOrganizationReportInfo(
        orgReportUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 기업 보고서 삭제 (숨김 처리)
  async v1AdminOrganizationReportDelete(
    orgReportUuid: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminOrganizationReportDelete(
        orgReportUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  /***** 수검자 통계 *****/
  // 마음검진 이용 통계
  async v1AdminStatisticsDailyCount(
    param: {
      startDate?: string;
      endDate?: string;
      institutionCode?: string;
    } = {},
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminStatisticsDailyCountResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminStatisticsDailyCount(
        param,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 마음검진 이용 현황 (성별)
  async v1AdminStatisticsGenderCount(
    param: {
      startDate?: string;
      endDate?: string;
      institutionCode?: string;
    } = {},
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminStatisticsGenderCountResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminStatisticsGenderCount(
        param,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 마음검진 이용 현황 (연령대)
  async v1AdminStatisticsAgeCount(
    param: {
      startDate?: string;
      endDate?: string;
      institutionCode?: string;
    } = {},
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminStatisticsAgeCountResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminStatisticsAgeCount(
        param,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  // 증상 별 유소견자 추이
  async v1AdminStatisticsSymptomaticCount(
    param: {
      startDate?: string;
      endDate?: string;
      institutionCode?: string;
    } = {},
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AdminStatisticsSymptomaticCountResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v1AdminStatisticsSymptomaticCount(
        param,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }
}
