import { IAdmin, ISetting, RootState, state } from "@/store/state";
import { MutationTree } from "vuex";

enum MutationTypes {
  CHANGE_LOADING_STATE = "CHANGE_LOADING_STATE",
  ADMIN_SET = "ADMIN_SET",
  SETTING_SET = "SETTING_SET",
  CENTER_ON_PAGE_SET = "CENTER_ON_PAGE_SET",
  SET_SERVER_URL = "SET_SERVER_URL",
}

const mutations: MutationTree<typeof state> = {
  [MutationTypes.ADMIN_SET](state: RootState, admin: IAdmin): void {
    state.admin = admin;
  },
  [MutationTypes.CHANGE_LOADING_STATE](
    state: RootState,
    boolean: boolean
  ): void {
    state.setting.loading = boolean;
  },
  [MutationTypes.SETTING_SET](state: RootState, setting: ISetting) {
    state.setting = { ...state.setting, ...setting };
  },
  [MutationTypes.CENTER_ON_PAGE_SET](
    state: RootState,
    selectedCenterOnPage: string
  ) {
    state.selectedCenterOnPage = selectedCenterOnPage;
  },

  [MutationTypes.SET_SERVER_URL](state: RootState, url: string) {
    state.serverUrl = url;
  },
};

type Mutations = typeof mutations;

export { MutationTypes, mutations, Mutations };
