import Vue, { VueConstructor } from "vue";
import firebase from "firebase/app";
// import * as adminAPI from "@/generated/adminapi/index";
import { ToastOptions, ToastType } from "vue-toasted";
import DateTime from "@/plugins/haii/DateTime";
import Util from "@/plugins/haii/Util";
import { AdminServerClass } from "@/plugins/server";
import { MutationTypes } from "@/store/mutations";

export interface IHaii {
  util: typeof Util;
  datetime: typeof DateTime;
  adminServer: AdminServerClass;
}

declare module "vue/types/vue" {
  interface Vue {
    adminServer: AdminServerClass;
    $firebase: typeof firebase;
    $_toastMessage: (
      icon: ToastType,
      message: string,
      timer?: number,
      option?: ToastOptions
    ) => void;
    $_errorMessage: (
      message: string,
      timer?: number,
      option?: ToastOptions
    ) => void;
    $_haii: IHaii;
    $_haiiName: () => string;
    $_haiiDictionary: (
      index: string,
      key: string,
      ...params: Array<string | number>
    ) => string;
    $_haiiText: (key: string, ...params: Array<string | number>) => string;
    $_haiiBGColor: (color: string, level?: number) => string;
    $_changeLoadingState: (state: boolean) => void;
  }
}
interface addToastOptionsType extends ToastOptions {
  keepOnHover?: boolean;
}

export default {
  install: (v: VueConstructor): void => {
    v.prototype.$_haii = {
      util: Util,
      datetime: DateTime,
      adminServer: new AdminServerClass(),
    };

    // 함수를 전역적으로 사용하기 위해
    v.mixin({
      methods: {
        $_toastMessage(
          icon: ToastType,
          message: string,
          timer?: number,
          option?: addToastOptionsType
        ) {
          // 20글자 마다 줄바꿈
          message = (message + "").replace(/(.{20})/g, "$1<br/>");
          switch (icon) {
            case "success": {
              this.$toasted.success(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-check-circle-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                      position: "top-center",
                    }
              );

              break;
            }
            case "error": {
              this.$toasted.error(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-alert-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                      position: "top-center",
                    }
              );
              break;
            }
            case "info": {
              this.$toasted.info(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-information-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                      position: "top-center",
                    }
              );
              break;
            }
            default: {
              this.$toasted.show("에러");

              break;
            }
          }
        },
        $_errorMessage(
          message: string,
          timer?: number,
          option?: addToastOptionsType
        ) {
          try {
            message = (message + "").replace(/(.{20})/g, "$1<br/>");
            this.$toasted.error(
              message,
              option
                ? option
                : {
                    icon: "mdi-alert-outline",
                    duration: timer ? timer : 3000,
                    keepOnHover: true,
                    position: "top-center",
                  }
            );
          } catch (e) {
            console.warn(e);
          }
        },
        $_haiiName(): string {
          const vConstructor = this.constructor as typeof Vue.prototype;

          return vConstructor.options.name;
        },

        $_haiiDictionary(
          index: string,
          key: string,
          ...params: Array<string | number>
        ): string {
          return key
            ? this.$vuetify.lang.t(`$vuetify.${index}.${key}`, ...params)
            : key;
        },

        $_haiiText(key: string, ...params: Array<string | number>): string {
          return this.$_haiiDictionary(this.$_haiiName(), key, ...params);
        },
        $_changeLoadingState(state: boolean) {
          return this.$store.commit(MutationTypes.CHANGE_LOADING_STATE, state);
        },
      },
    });
  },
};
