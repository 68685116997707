import { RootState, ISetting, SelectInterface, state } from "./state";
import { GetterTree } from "vuex";

export const getters: GetterTree<typeof state, RootState> = {
  setting: (state: RootState): ISetting => state.setting,
  getCenterItems: (state: RootState): Array<SelectInterface> =>
    state.centerItems,
  getSelectedCenterOnPage: (state: RootState): string =>
    state.selectedCenterOnPage,
  getServerUrl: (state: RootState): string => state.serverUrl,
};

export type Getters = typeof getters;
