import mitt, { Emitter } from "mitt";
import { VueConstructor } from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $mitt: Emitter<{
      refresh: void;
      refreshTime: void;
      getCsData: void;
      getCsComments: void;
      csValidate: void;
      csInfoValidate: void;
      csValidationReset: void;
    }>;
  }
}

export const mittUse = mitt();

const emitter = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$mitt = mittUse;
  },
};

export default emitter;
