import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#005282",
        secondary: "#02aac6",
        tertiary: "#555555",
        selectedBackground: "#e8f0fe",
        selectedText: "#1867d1",
      },
    },
  },
});
