import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import haii from "@/plugins/haii";
import "./plugins/firebase/firebase";
import "./plugins/toasted";
import "./assets/default.scss";
import "@/registerServiceWorker";
import mitt from "@/plugins/mitt";
import HaiiExAuth, { HaiiAuth } from "@/plugins/HaiiExAuth";
import Zendesk from "@/plugins/Zendesk";
import Slack from "@/plugins/Slack";

// production임을 검증하기 위함
// 기존에는 App.vue의 create() 내에서 실행
// 그런 경우 process.env.VUE_APP_ENV 값을 가져오기 전에 isProduction을 판단하는 바람에 production인데 dev를 붙임
// 따라서 process.env.VUE_APP_ENV 값을 가져온 후 isProduction을 판단하기 위해 main.ts에서 HaiiAuth.Instance.init()
HaiiAuth.Instance.init({
  // signInRedirectURI: process.env.VUE_APP_BASE_URL + "dashboard", // TODO: 로그인하고 접근할 URL or 로그아웃 버튼 눌렀을 때 redirect url을 dashboard로 넘김
  serviceName: "tess",
  isProduction: process.env.VUE_APP_ENV === "production",
});

Vue.use(haii);
Vue.use(mitt);
Vue.use(Zendesk);
Vue.use(Slack);
Vue.use(HaiiExAuth);
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
