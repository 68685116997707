/**
 * TESS Server
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: "http://localhost:8880/tess",
};
const oazapfts = Oazapfts.runtime(defaults);
export type V1AdminAdminuserResponse = {
  exid?: string;
  uuid?: string;
  name?: string;
  email?: string;
  roles?: string[];
  mobile?: string;
  orgName?: string;
  orgCode?: string;
};
export type V1AdminAdminuserInfoResponse = V1AdminAdminuserResponse;
export type GenericResponse = {
  code: number;
  message: string | object;
};
export type V1AdminAdminuserCreateRequest = {
  exid: string;
  name: string;
  email: string;
  roles: string[];
  mobile: string;
  orgName: string;
  orgCode?: string;
};
export type V1AdminAdminuserCreateResponse = V1AdminAdminuserResponse;
export type V1AdminAdminuserUpdateRequest = {
  name?: string;
  email?: string;
  roles?: string[];
  mobile?: string;
  orgName?: string;
  orgCode?: string;
};
export type V1AdminAdminuserUpdateResponse = V1AdminAdminuserResponse;
export type V1AdminDashboardMonthlyCountResponse = {
  totalCount: number;
  examineesCount: number;
};
export type V1AdminDashboardOrgReportCountResponse = {
  totalCount: number;
  monthlyCount: number;
  todayCount: number;
};
export type V1ExamineeDetail = {
  no?: number;
  institutionName?: string;
  institutionCode?: string;
  examineeName?: string;
  birthdate?: string;
  registerNo?: string;
  medicalCheckUpFinishDate?: string;
  assessmentFinishDate?: string;
  finishFlag?: boolean;
  companyCode?: string;
  organizationName?: string;
  gender?: string;
  email?: string;
  lCode?: string;
  uuid?: string;
  licenseCreateDate?: string;
  licenseModifyDate?: string;
};
export type V1ExamineesListResponse = {
  totalCount: number;
  list: V1ExamineeDetail[];
};
export type V1AdminExamineesCompanyCodesValidateResponse = {
  isValid: boolean;
};
export type V1ExamineeUpdateRequest = {
  institutionName?: string;
  institutionCode?: string;
  examineeName?: string;
  birthdate?: string;
  registerNo?: string;
  medicalCheckUpFinishDate?: string;
  anzeilaxFinishDate?: string;
  allFinishFlag?: boolean;
  companyCode?: string;
};
export type V1ExamineeUpdateResponse = V1ExamineeDetail;
export type V1AdminLicensesResponseDetail = {
  rgstno?: string;
  userName?: string;
  institutionCode?: string;
};
export type V1AdminLicensesResponse = V1AdminLicensesResponseDetail[];
export type V1NotificationDetail = {
  uuid: string;
  sender: string;
  receiveTime: string;
  type: number;
  content?: string;
  read: boolean;
  url: string;
};
export type V1NotificationsResponse = V1NotificationDetail[];
export type V1NotificationCreateRequest = {
  receiverUUID: string;
  content?: string;
  type: number;
  url?: string;
};
export type V1NotificationUpdateReadResponse = V1NotificationDetail;
export type V1AdminOrgReportsResponseDetail = {
  no?: number;
  uuid: string;
  orgCode: string[];
  startDate: string;
  endDate: string;
  totalCount: number;
  examineesCount: number;
  symptomaticCount: number;
  createdDate: string;
  name: string;
  hashName: string;
  author: string;
};
export type V1AdminOrgReportsResponse = {
  totalCount: number;
  list: V1AdminOrgReportsResponseDetail[];
};
export type V1AdminOrgReportsCreateRequest = {
  name: string;
  orgCode: string[];
  startDate: string;
  endDate: string;
  examineesList: string[];
  totalCount: number;
  requestDate?: string;
  departmentClassification?: {
    code: string;
    name: string;
  }[];
};
export type V1AdminOrganizationReportInfoResponse =
  V1AdminOrgReportsResponseDetail & {
    examineesList: string[];
  };
export type V1AdminStatisticsDailyCountResponse = object;
export type V1AdminStatisticsGenderCountResponse = {
  female: number;
  male: number;
  total: number;
};
export type V1AdminStatisticsAgeCountResponse = {
  "10s": number;
  "20s": number;
  "30s": number;
  "40s": number;
  "50s": number;
  "60s": number;
  "70s": number;
  total: number;
};
export type V1AdminStatisticsSymptomaticCountResponse = {
  GAD7: number[];
  ADNM4: number[];
  ISI: number[];
  PHQ9: number[];
  PCPTSD5: number[];
  CSS: number[];
  total: number[];
};
/**
 * adminuser 정보 조회
 */
export function v1AdminAdminuserInfo(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminAdminuserInfoResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/adminuser", {
    ...opts,
  });
}
/**
 * adminuser 생성
 */
export function v1AdminAdminuserCreate(
  v1AdminAdminuserCreateRequest: V1AdminAdminuserCreateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminAdminuserCreateResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/adminuser",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1AdminAdminuserCreateRequest,
    })
  );
}
/**
 * adminuser 수정
 */
export function v1AdminAdminuserUpdate(
  v1AdminAdminuserUpdateRequest: V1AdminAdminuserUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminAdminuserUpdateResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/adminuser",
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: v1AdminAdminuserUpdateRequest,
    })
  );
}
/**
 * adminuser 정보 수정
 */
export function v1AdminAdminuserUpdateByExid(
  exid: string,
  v1AdminAdminuserUpdateRequest: V1AdminAdminuserUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminAdminuserUpdateResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/adminuser/${exid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: v1AdminAdminuserUpdateRequest,
    })
  );
}
/**
 * 당월 검진 대상자/수검자 수
 */
export function v1AdminDashboardMonthlyCount(
  {
    institutionCode,
  }: {
    institutionCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminDashboardMonthlyCountResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/dashboard/monthlyCount${QS.query(
      QS.form({
        institutionCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * dashboard 기업리포트 수 조회
 */
export function v1AdminDashboardOrgReportCount(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminDashboardOrgReportCountResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/admin/dashboard/orgReportCount", {
    ...opts,
  });
}
/**
 * 검진자 정보 목록 조회
 */
export function v1AdminExaminees(
  {
    institutionCode,
    finishFlag,
    startDate,
    endDate,
    searchKeyword,
    searchRgstno,
    searchLCode,
    companyCodeList,
    uuidList,
    pagination,
  }: {
    institutionCode?: string;
    finishFlag?: boolean;
    startDate?: string;
    endDate?: string;
    searchKeyword?: string;
    searchRgstno?: string;
    searchLCode?: string;
    companyCodeList?: any;
    uuidList?: any;
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1ExamineesListResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/examinees${QS.query(
      QS.form({
        institutionCode,
        finishFlag,
        startDate,
        endDate,
        searchKeyword,
        searchRgstno,
        searchLCode,
        companyCodeList,
        uuidList,
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 사업장 코드(cmpycd) check
 */
export function v1AdminExamineesCompanyCodesValidate(
  cmpycd: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminExamineesCompanyCodesValidateResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/examinees/companyCodes/validate.do${QS.query(
      QS.form({
        cmpycd,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 검진자 정보 수정
 */
export function v1AdminExamineeUpdate(
  examineeUuid: string,
  v1ExamineeUpdateRequest: V1ExamineeUpdateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1ExamineeUpdateResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/examinees/${examineeUuid}`,
    oazapfts.json({
      ...opts,
      method: "PATCH",
      body: v1ExamineeUpdateRequest,
    })
  );
}
/**
 * 라이센스 정보 조회
 */
export function v1AdminLicenses(
  {
    rgstno,
    institutionCode,
  }: {
    rgstno?: string;
    institutionCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminLicensesResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/licenses${QS.query(
      QS.form({
        rgstno,
        institutionCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 알림 목록 조회
 */
export function v1AdminNotifications(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1NotificationsResponse;
      }
    | {
        status: 414;
        data: GenericResponse;
      }
  >("/v1/admin/notifications", {
    ...opts,
  });
}
/**
 * 알림 생성
 */
export function v1AdminNotificationCreate(
  v1NotificationCreateRequest: V1NotificationCreateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/notifications",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1NotificationCreateRequest,
    })
  );
}
/**
 * 로그인된 adminUser 알림 삭제
 */
export function v1AdminNotificationsDelete(
  readFlag: "Y" | "N",
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/notifications${QS.query(
      QS.form({
        readFlag,
      })
    )}`,
    {
      ...opts,
      method: "DELETE",
    }
  );
}
/**
 * 특정 알림 삭제
 */
export function v1AdminNotificationDelete(
  notificationUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/notifications/${notificationUuid}`, {
    ...opts,
    method: "DELETE",
  });
}
/**
 * 알림 읽음 처리
 */
export function v1AdminNotificationUpdateRead(
  notificationUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1NotificationUpdateReadResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/notifications/${notificationUuid}/read.do`, {
    ...opts,
    method: "POST",
  });
}
/**
 * 기업 리포트 목록 조회
 */
export function v1AdminOrgReports(
  {
    orgCode,
    startDate,
    endDate,
    pagination,
  }: {
    orgCode?: string;
    startDate?: string;
    endDate?: string;
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminOrgReportsResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/orgReports${QS.query(
      QS.form({
        orgCode,
        startDate,
        endDate,
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 기업보고서 생성
 */
export function v1AdminOrgReportsCreate(
  v1AdminOrgReportsCreateRequest: V1AdminOrgReportsCreateRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminOrgReportsResponseDetail;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/admin/orgReports",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1AdminOrgReportsCreateRequest,
    })
  );
}
/**
 * 기업보고서 상세보기
 */
export function v1AdminOrganizationReportInfo(
  orgReportUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminOrganizationReportInfoResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/orgReports/${orgReportUuid}`, {
    ...opts,
  });
}
/**
 * 기업보고서 삭제
 */
export function v1AdminOrganizationReportDelete(
  orgReportUuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/admin/orgReports/${orgReportUuid}`, {
    ...opts,
    method: "DELETE",
  });
}
/**
 * 날짜별 검진자 수
 */
export function v1AdminStatisticsDailyCount(
  {
    startDate,
    endDate,
    institutionCode,
  }: {
    startDate?: string;
    endDate?: string;
    institutionCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminStatisticsDailyCountResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/statistics/dailyCount${QS.query(
      QS.form({
        startDate,
        endDate,
        institutionCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 성별 검진자 수
 */
export function v1AdminStatisticsGenderCount(
  {
    startDate,
    endDate,
    institutionCode,
  }: {
    startDate?: string;
    endDate?: string;
    institutionCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminStatisticsGenderCountResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/statistics/genderCount${QS.query(
      QS.form({
        startDate,
        endDate,
        institutionCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 연령대별 검진자 수
 */
export function v1AdminStatisticsAgeCount(
  {
    startDate,
    endDate,
    institutionCode,
  }: {
    startDate?: string;
    endDate?: string;
    institutionCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminStatisticsAgeCountResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/statistics/ageCount${QS.query(
      QS.form({
        startDate,
        endDate,
        institutionCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 유소견자 수
 */
export function v1AdminStatisticsSymptomaticCount(
  {
    startDate,
    endDate,
    institutionCode,
  }: {
    startDate?: string;
    endDate?: string;
    institutionCode?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AdminStatisticsSymptomaticCountResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/admin/statistics/symptomaticCount${QS.query(
      QS.form({
        startDate,
        endDate,
        institutionCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
