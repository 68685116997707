import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import { Cookie, HaiiAuth } from "@/plugins/HaiiExAuth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "TessAdmin",
    redirect: "/dashboard",
    meta: {
      authRequired: true,
      auth: ["REPORT", "ADJUSTMENT", "CS", "CENTER"],
    },
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/layouts/default/index.vue"
      ),
    children: [
      /***** Dashboard *****/
      {
        path: "dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "@/views/DashBoard.vue"
          ),
        meta: {
          authRequired: true,
          auth: ["REPORT", "ADJUSTMENT", "CS", "CENTER"],
        },
      },
      /***** 사용자 정보 *****/
      {
        path: "examinee",
        name: "ExamineeInfo",
        component: () =>
          import(
            /* webpackChunkName: "examinee-info" */
            "@/views/ExamineeInfo.vue"
          ),
        meta: {
          authRequired: true,
          auth: ["CS", "CENTER"],
        },
      },
      /***** 기업 보고서 관리 *****/
      {
        path: "report",
        name: "Report",
        component: () =>
          import(
            /* webpackChunkName: "report" */
            "@/views/CompanyReport.vue"
          ),
        meta: {
          authRequired: true,
          auth: ["REPORT"],
        },
      },
      /***** CS 현황 *****/
      {
        path: "cs",
        name: "CsStatus",
        component: () =>
          import(
            /* webpackChunkName: "cs" */
            "@/views/CsStatus.vue"
          ),
        meta: {
          authRequired: true,
          auth: ["CS", "CENTER"],
        },
      },
      /***** 수검자 통계 *****/
      {
        path: "statistics",
        name: "ExamineesStatistics",
        component: () =>
          import(
            /* webpackChunkName: "examinee-statistics" */
            "@/views/ExamineesStatistics.vue"
          ),
        meta: {
          authRequired: true,
          auth: ["REPORT", "ADJUSTMENT", "CS", "CENTER"],
        },
      },
    ],
  },
];

const authRoutes: Array<RouteConfig> = [
  /***** Auth *****/
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */
        "@/views/auth/ChangePassword.vue"
      ),
  },
  {
    path: "/change-contact",
    name: "ChangeContact",
    component: () =>
      import(
        /* webpackChunkName: "change-contact" */
        "@/views/auth/ChangeContact.vue"
      ),
  },
];

const defaultRoute: Array<RouteConfig> = [
  /***** Auth *****/
  {
    path: "",
    name: "Auth",
    redirect: "/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/layouts/default/index.vue"
      ),
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...defaultRoute, ...authRoutes, ...routes],
});

router.beforeEach(async (to, _from, next) => {
  await HaiiAuth.Instance.manageToken(true);

  const authenticationStates = store.state.admin.authorityList;
  const authorization = to.meta;
  let authFlag = false;

  if (authorization?.authRequired) {
    for (const authenticationState of authenticationStates) {
      for (const authName of authorization.auth) {
        if (authName === authenticationState) {
          authFlag = true;
        }
      }
    }
    if (!!Cookie.getCookie("_aat") && !authFlag) {
      alert("권한 없음");
      // next(`/${authenticationState}`);
      // next(from);
      router.go(-1);
    }
  }

  next();
});

export default router;
