export interface SelectInterface {
  text: string;
  value: string;
}

export interface IAdmin {
  uid: string;
  name: string;
  email: string;
  authorityList: Array<string>;
  authority?: string;
  agency?: string;
  centerCode?: number;
  password?: string;
}

export interface ISetting {
  loading: boolean;
  locale: string;
}

interface RootState {
  admin: IAdmin;
  setting: ISetting;
  centerItems: SelectInterface[];
  selectedCenterOnPage: string;
  serverUrl: string;
}

const state: RootState = {
  admin: {
    uid: "",
    name: "",
    email: "",
    authorityList: [],
    authority: "",
    agency: "",
    centerCode: 0,
    password: "",
  },
  setting: {
    loading: false,
    locale: "ko",
  },
  centerItems: [],
  selectedCenterOnPage: "",
  serverUrl: "",
};

export { state, RootState };
