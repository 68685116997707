import { MutationTypes } from "@/store/mutations";
import { ActionTree } from "vuex";
import { state, RootState, IAdmin } from "@/store/state";

enum ActionTypes {
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
}

const actions: ActionTree<typeof state, RootState> = {
  [ActionTypes.SIGN_IN]({ commit }, admin: IAdmin): void {
    commit(MutationTypes.ADMIN_SET, admin);
  },
  [ActionTypes.SIGN_OUT]({ commit }): void {
    commit(MutationTypes.ADMIN_SET, {});
  },
};

type Actions = typeof actions;

export { ActionTypes, actions, Actions };
