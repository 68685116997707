import { DataOptions } from "vuetify";
import { ICsFilter } from "@/types/csDataTypes";

export const itemsPerPageOptions = [
  { text: "20개씩 보기", value: 20 },
  { text: "40개씩 보기", value: 40 },
  { text: "60개씩 보기", value: 60 },
  { text: "80개씩 보기", value: 80 },
];

export const centerList = [
  { text: "광화문", value: "111" },
  { text: "여의도", value: "112" },
  { text: "강남", value: "113" },
  { text: "수원", value: "211" },
  { text: "대구", value: "611" },
  { text: "부산", value: "612" },
  { text: "광주", value: "711" },
];

export const centerDict: { [key: string]: string } = {
  "111": "광화문",
  "112": "여의도",
  "113": "강남",
  "211": "수원",
  "611": "대구",
  "612": "부산",
  "711": "광주",
  "123": "T센터",
};

export const isCompleted = [
  { text: "완료", value: true },
  { text: "미완료", value: false },
];

export const DEFAULT_DATA_OPTIONS: Required<DataOptions> = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
};

export const DEFAULT_CS_FILTER: Required<ICsFilter> = {
  selectedCenter: "",
  dates: ["", ""],
  selectedCsType: [],
  selectedStatus: [],
  searchContents: "",
  id: 0,
};
