




import Vue from "vue";
import { Component } from "vue-property-decorator";
import { MutationTypes } from "@/store/mutations";
import { Cookie } from "@/plugins/HaiiExAuth";

@Component
export default class App extends Vue {
  created() {
    // let url = (api.servers as Record<string, string>)[runtime.api];

    const token = Cookie.getCookie("_aat") as string;

    if (!token) return;
    console.log(token);

    const payload = JSON.parse(
      decodeURIComponent(escape(window.atob(token.split(".")[1])))
    );

    let authorityCodeList: string[] = [];
    let authorityLevelList: number[] = [];
    payload.rol.forEach((role: string) => {
      const splitAuth = role.split(".");
      authorityLevelList.push(Number(splitAuth[1]));
      authorityCodeList.push(splitAuth[2]);
    });
    const centerCode = authorityLevelList.filter((level) => level > 100)[0];

    let authority = "";

    if (authorityCodeList.length === 6 || authorityCodeList.includes("OWNER")) {
      authority = "Owner";
    } else if (
      authorityCodeList.length === 2 ||
      authorityCodeList.length === 4
    ) {
      authority = "Admins";
    } else {
      authority = "Editors";
    }

    this.$store.commit(MutationTypes.ADMIN_SET, {
      uid: payload.sub,
      name: payload.dn,
      email: payload.em,
      centerCode: centerCode,
      authorityList: authorityCodeList,
      authority: authority,
    });
  }
}
