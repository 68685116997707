import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { RootState, state } from "./state";
import { getters } from "./getters";
import { mutations, MutationTypes } from "./mutations";
import { actions } from "./actions";

Vue.use(Vuex);

// 새로고침을 해도 데이터를 유지하기 위함
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  // state 중 유지할 데이터만 선별
  reducer: (state: RootState) => ({
    admin: state.admin,
    centerCode: state.admin.centerCode,
    authorityList: state.admin.authorityList,
    authority: state.admin.authority,
    centerItems: state.centerItems,
    selectedCenterOnPage: state.selectedCenterOnPage,
    serverUrl: state.serverUrl,
    password: state.admin.password,
  }),

  // mutation 중 변경사항 반영할 state 가 존재하는 경우, 만약 기본적으론 모든 mutation이 true 로 반영
  filter: (mutations): boolean => {
    switch (mutations.type) {
      case MutationTypes.ADMIN_SET:
        return true;
      case MutationTypes.SET_SERVER_URL:
        return true;
      default:
        break;
    }
    return false;
  },
});

export default new Vuex.Store({
  plugins: [vuexSession.plugin],
  state,
  getters,
  mutations,
  actions,
});
